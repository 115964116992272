
import { defineComponent } from "vue";
import { loadFull } from "tsparticles";
import {
  IonIcon
} from "@ionic/vue";
import {
  cart,
  reorderThreeOutline
} from "ionicons/icons";

export default defineComponent({
  name: "HeaderComponent",
  components: {
    IonIcon
  },

  data() {
    return {
      reorderThreeOutline,
      cart,
      options: {
        style: {
          position: "static",
          "z-index": -1,
          height: "100%",
          width: "100%",
        },
        fpsLimit: 60,
        duration: 0,
        interactivity: {
          events: {
            onClick: {
              enable: false,
            },
            onHover: {
              enable: false,
            },
            resize: true,
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.125,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 500,
            },
            value: 75,
          },
          opacity: {
            value: 0.125,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 3 },
          },
        },
        detectRetina: true,
      },
      showHeaderMenu: false
    };
  },
  methods: {
    particlesInit: async (engine: any) => {
      await loadFull(engine);
    },
  },
});
