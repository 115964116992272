import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "notifications" }
const _hoisted_3 = { class: "notification__body" }
const _hoisted_4 = {
  key: 0,
  class: "notification__title"
}
const _hoisted_5 = { class: "notification__content" }
const _hoisted_6 = { class: "notification__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_page, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_HeaderComponent),
            _createElementVNode("main", null, [
              _createVNode(_component_router_view)
            ]),
            _createVNode(_component_FooterComponent)
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationStore.notificationsList, (notification) => {
              return (_openBlock(), _createElementBlock("div", {
                key: notification.id,
                class: _normalizeClass('notification notification-' + notification.type)
              }, [
                _createElementVNode("div", _hoisted_3, [
                  (notification.title)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("h5", null, _toDisplayString(notification.title), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("p", null, _toDisplayString(notification.content), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.closeCircleOutline,
                    onClick: ($event: any) => (
                this.notificationStore.removeNotification(notification.id)
              )
                  }, null, 8, ["icon", "onClick"])
                ])
              ], 2))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}