import { defineStore } from "pinia";

export const useTimerStore = defineStore('timerStore', {
  state: () => ({
    timer: null as any
  }),
  getters: {
    getTimer(state) {
      return state.timer;
    },
  }
});
