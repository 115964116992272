
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";
import { ellipse } from "ionicons/icons";

export default defineComponent({
  name: "FooterComponent",
  components: {
    IonIcon,
  },

  data() {
    return {
      ellipse,
    };
  },
});
