
import { IonApp, IonPage, IonIcon } from "@ionic/vue";
import { defineComponent } from "vue";
import HeaderComponent from "./components/header.vue";
import FooterComponent from "./components/footer.vue";
import statusCheck from "./common/status";
import { useNotificationStore } from "./store/notifications";
import { closeCircleOutline } from "ionicons/icons";


const notificationStore = useNotificationStore();

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonPage,
    IonIcon,
    HeaderComponent,
    FooterComponent
  },
  data() {
    return {
      notificationStore,
      closeCircleOutline,
    };
  },
  async beforeMount() {
    await statusCheck();
  }
})
