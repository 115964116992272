import { defineStore } from "pinia";

export interface notification {
  id: string,
  title: string | null,
  content: string,
  timer: any,
  duration: number,
  type: string
}

export const useNotificationStore = defineStore('notificationStore', {
  state: () => ({
    isPanelOpen: false as boolean,
    notificationsList: [
    ] as notification[]
  }),
  actions: {
    addNotification(notification: { title?: string, content: string, duration?: number, type?: string }): void {
      const id = String(Math.random())
      const duration = notification.duration ?? 5000;

      const timer = setTimeout(() => {
        this.removeNotification(id)
      }, duration)

      this.notificationsList.push({
        id: id,
        title: notification.title ?? null,
        content: notification.content,
        timer: timer,
        duration: duration,
        type: notification.type ?? 'primary',
      })
    },
    removeNotification(id: string): any {
      this.notificationsList = this.notificationsList.filter((notification) => {

        if (notification.id === id) {
          clearTimeout(notification.timer)
          return false
        }
        return true
      })
    }
  },
  getters: {
    getNotifications(): notification[] {
      return this.notificationsList
    }
  }
})