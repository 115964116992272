import { defineStore } from "pinia";

export interface userData {
  userId?: number | undefined,
  name?: string | undefined,
  surname?: string | undefined,
  email?: string | undefined,
  role?: string | undefined
}

export const useUserStore = defineStore('userStore', {
  state: () => ({
    userId: null as number | null,
    email: null as string | null,
    name: null as string | null,
    surname: null as string | null,
    role: null as string | null
  }),
  actions: {
    setUser(payload: userData): void {
      if (payload.userId)
        this.userId = payload.userId
      if (payload.name)
        this.name = payload.name
      if (payload.surname)
        this.surname = payload.surname
      if (payload.email)
        this.email = payload.email
      if (payload.role)
        this.role = payload.role
    },
    clear(): void {
      this.$state.userId = null
      this.$state.email = null
      this.$state.name = null
      this.$state.surname = null
      this.$state.role = null
    }
  },
  getters: {
    getUserId(state) {
      return state.userId;
    },
    getUserData(state) {
      return {
        name: state.name,
        surname: state.surname,
        email: state.email,
      };
    },
  }
});
