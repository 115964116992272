
import { arrowForwardOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "HomePage",
  components: {
    IonIcon
  },
  data() {
    return {
      arrowForwardOutline
    }
  }
});
